<template>
    <ButtonTab 
            v-for="(dossier, index) in props.data.dossier_data" 
            :key="dossier.id"
            :active="dossier.tab_number === store.activeTab"
            @click="selectData(dossier.tab_number)"
        >
            Escenario {{ index + 1 }}
    </ButtonTab>
    <div @click="addNewScenario" class="flex p-3 h-[42px] justify-center items-center gap-2 cursor-pointer rounded-[10px] border border-[#E9E9E9] bg-white text-gray-700 hover:bg-gray-100 hover:border-black group">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="w-5 h-5 stroke-gray-500 group-hover:stroke-black">
            <path d="M10.0001 1.66675V10.0001M10.0001 10.0001V18.3334M10.0001 10.0001H1.66675M10.0001 10.0001H18.3334" stroke-width="2.5" stroke-linecap="round"/>
        </svg>
    </div>
</template>

<script setup>
import ButtonTab from '@/components/ButtonTab.vue';

import { useCalculatorStore } from '@/stores/useCalculatorStore';
const store = useCalculatorStore();
//emits selecdata
const emit = defineEmits(['selectData']);

const props = defineProps({
    data: {
        type: Object,
        required: true
    },
    form: {
        type: Object,
        required: true
    }
});


const selectData = (tab_number) => {
    emit('selectData', tab_number);
};

const addNewScenario =async () => {
  await store.addNewScenario(store.form);
  
}

</script>

