<template>
  <SliderComponent useScroll :showButtons="true">
    <!-- Slide 1 -->
    <template #slide-1>
        <ProfitCalculator :data="data" shortMode:shortMode @updateDossierData="addNewDossier"/>
    </template>
    <!-- Slide 2 -->
    <template #slide-2>
      <OnlineReputation />
    </template>

    <!-- Slide 3 -->
    <template #slide-3>
      <DigitalGuestExperience />
    </template>

    <!-- Slide 4 -->
    <template #slide-4>
      <div class="flex justify-center items-center h-[760px]">
        <TablePrice shortMode:shortMode />
      </div>
    </template>

    <template #slide-5>
      <div class="flex justify-center items-center h-[760px]">
        <TablePriceShow shortMode:shortMode />
      </div>
    </template>

    <!-- Slide 5 -->
    <template #slide-6>
      <div class="flex flex-col justify-center items-center h-[760px] w-full">
        <Calculator shortMode:shortMode :data="data" @updateDossierData="addNewDossier"/>
      </div>
    </template>
  </SliderComponent>
</template>

<script setup>
  import { onMounted, computed } from 'vue';
  import SliderComponent from './SliderComponent.vue';
  import Calculator from '../../components/CalculatorSlider.vue';
  import ProfitCalculator from '../../components/ProfitCalculator.vue';
  import TablePrice from './Components/TablePrice.vue';
  import TablePriceShow from './Components/TablePriceShow.vue';
  import OnlineReputation from './Components/OnlineReputation.vue';
  import DigitalGuestExperience from './Components/DigitalGuestExperience.vue';
 

  import { useRoute } from 'vue-router';
  const route = useRoute();

  import { useCalculatorStore } from '@/stores/useCalculatorStore';
  const store = useCalculatorStore();


  onMounted(async () => {
    await store.fetchDossierData(route.params.domain, route.params.type);
    
  });



  const data = computed(() => store?.dossierData);

  const addNewDossier = (newDossier) => {
      store.dossierData.dossier_data.push(newDossier.data_dossier); 
  };



</script>
<style>

  .custom-span {
    color: inherit !important;
  }

  p,
  h1 {
    font-family: 'Rethink Sans', sans-serif !important;
    color: #333333 !important;
  }

  .slider-content {
    font-family: 'Rethink Sans', sans-serif !important;
  }

  .svg-black {
    filter: brightness(0) saturate(100%) invert(14%) sepia(2%) saturate(0%) hue-rotate(194deg) brightness(97%) contrast(89%);
  }

  .border-separation-mid {
    background-color: #333333;
    height: 3px !important;
  }

  .border-separation-mid-white {
    background-color: white;
    height: 3px;
  }

  @media (max-width: 1440px) {
    .max-w-[1680px] {
      max-width: 1200px;
    }
  }
</style>
