<template>
  <div class="relative w-full h-screen overflow-hidden bg-[#FFFFFF]" @wheel="handleScroll">
    <div v-if="currentSlide && currentSlide.header" class="absolute top-0 left-0 w-full z-20 bg-[#FFFFFF] pr-[120px] pl-[120px]">
      <div class="flex justify-between items-center pt-[24px] pb-[24px] pr-[40px]">
        <h2 class="text-[24px] font-normal uppercase">{{ currentSlide.title }}</h2>
        <img src="/img/1.TH.logo.svg" alt="Logo" class="h-[29px] object-contain" />
      </div>
      <hr class="border-gray-300 pl-[40px] pr-[40px]" />
    </div>

    <!-- slider fijo con el logo central -->
    <div
      v-if="currentIndex === 0"
      class="relative flex items-center justify-center bg-[#FFFFFF] w-full h-screen mx-auto"
    >
      <div class="absolute top-0 w-full">
        <img src="/img/presentacion/GuardaPNG.png" alt="Imagen con degradado a la derecha" class="w-full object-cover">
        <div class="absolute inset-0 bg-gradient-to-l from-white"></div>
      </div>
      
      <img src="/img/presentacion/LogoPNG.png" alt="Logo" class="h-[152px] object-contain my-4 z-10" />

      <div class="absolute bottom-0 w-full">
        <img src="/img/presentacion/GuardaPNG.png" alt="Imagen con degradado a la izquierda" class="w-full object-cover">
        <div class="absolute inset-0 bg-gradient-to-r from-white"></div>
      </div>
    </div>

    <!-- Diapositiva dinámica -->
    <transition :name="transitionName" mode="out-in">
      <div
        v-if="currentSlide"
        :key="currentSlide.name"
        class="relative flex flex-col justify-center bg-[#FFFFFF] mx-auto"
        :style="{ height: currentSlide.fullHeight ? '100vh' : '760px', marginTop: currentSlide.fullHeight ? '0' : currentSlide.marginT ? currentSlide.marginT : '24px' }"
        :class="{'w-full': currentSlide.fullWidth, 'max-w-[1680px]': !currentSlide.fullWidth}"
      >

        <slot :name="currentSlide.name"></slot>
      </div>
    </transition>

    <div v-if="currentIndex !== 0 && !currentSlide.fullHeight" class="w-full max-w-[1680px] mx-auto mt-[40px] flex justify-end">
      <button
        @click="prevSlide"
        :class="[isFirstSlide ? 'disabled-button' : 'button-active', 'p-2 rounded-l-lg border-none focus:outline-none']"
        :disabled="isFirstSlide"
      >
        <img src="/img/presentacion/up.svg" alt="Up" class="w-8 h-8" />
      </button>
      <button
        @click="nextSlide"
        :class="[isLastSlide ? 'disabled-button' : 'button-active', 'p-2 rounded-r-lg border-none focus:outline-none']"
        :disabled="isLastSlide"
      >
        <img src="/img/presentacion/down.svg" alt="Down" class="w-8 h-8" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';

const props = defineProps({
  useScroll: {
    type: Boolean,
    default: false,
  },
  showButtons: {
    type: Boolean,
    default: true,
  }
});

const currentIndex = ref(0);
const transitionName = ref('slide-up-down');

// Definición de las diapositivas, incluyendo `TablePrice` y `TablePriceShow`
const dynamicSlides = [
  { name: 'slide-1', header: true, layout: 'row', title: 'CALCULADORA DE INGRESOS', fullWidth: false, fullHeight: false , marginT: '24px' },
  { name: 'slide-2', header: true, layout: 'col', title: 'reputación online', fullWidth: false, fullHeight: false },
  { name: 'slide-3', header: true, layout: 'col', title: '¿cómo lo hacemos?', fullWidth: false, fullHeight: false },
  { name: 'slide-4', header: true, layout: 'col', title: 'PRICING', fullWidth: true, fullHeight: false },
  { name: 'slide-5', header: true, layout: 'col', title: 'PRICING', fullWidth: true, fullHeight: false },
  { name: 'slide-6', header: true, layout: 'row', title: 'CALCULADORA DE BENEFICIO', fullWidth: false, fullHeight: false , marginT: '118px' },
];

const shortMode = ref(false);

const filteredSlides = computed(() => {
  return shortMode.value ? dynamicSlides.slice(0, 3) : dynamicSlides;
});

const isFirstSlide = computed(() => currentIndex.value === 0);
const isLastSlide = computed(() => currentIndex.value === filteredSlides.value.length);
const currentSlide = computed(() => filteredSlides.value[currentIndex.value - 1] || null);

// Modificación solo en prevSlide
/* const prevSlide = () => {
  if (currentSlide.value?.name === 'slide-5') {
    transitionName.value = ''; // Sin transición al saltar `TablePriceShow`
    currentIndex.value -= 2; // Retrocede directamente a `TablePrice`
  } else if (currentIndex.value > 0) {
    transitionName.value = 'slide-down-up';
    currentIndex.value--;
  }
}; */

const prevSlide = () => {
  if (currentSlide.value?.name === 'slide-5') {
    // Retroceder desde `TablePriceShow` a `TablePrice`, sin transición
    transitionName.value = ''; 
    currentIndex.value--;
  } else if (currentSlide.value?.name === 'slide-4') {
    // Retroceder desde `TablePrice` al componente anterior con transición
    transitionName.value = 'slide-down-up'; // Restablece la transición
    currentIndex.value--;
  } else if (currentIndex.value > 0) {
    // Transición estándar de retroceso
    transitionName.value = 'slide-down-up';
    currentIndex.value--;
  }
};



// NextSlide tal cual fue proporcionado
const nextSlide = () => {
  if(currentSlide.value?.name == 'slide-6') {
    return false;
  }
  // Verificamos si estamos en `TablePrice` y `TablePriceShow` para desactivar la transición general
  if (currentSlide.value?.name === 'slide-4' && currentIndex.value + 1 < filteredSlides.value.length) {
    transitionName.value = ''; // Sin transición entre TablePrice y TablePriceShow
    currentIndex.value++;
  } else {
    transitionName.value = 'slide-up-down';
    currentIndex.value++;
  }
};

let isScrolling = false;

const handleScroll = (event) => {
  if (props.useScroll && !isScrolling) {
    isScrolling = true;
    if (event.deltaY < 0) {
      prevSlide();
    } else if (event.deltaY > 0) {
      nextSlide();
    }
    setTimeout(() => {
      isScrolling = false;
    }, 620);
  }
};

const handleKeydown = (event) => {
  if (event.key === 'ArrowUp') {
    prevSlide();
  } else if (event.key === 'ArrowDown') {
    nextSlide();
  }
};

onMounted(() => {
  window.addEventListener('keydown', handleKeydown);

  const queryParams = new URLSearchParams(window.location.search);
  shortMode.value = queryParams.get('short') === '1';
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeydown);
});
</script>

<style scoped>
/* Animación cuando avanzamos (Down), la página sube */
.slide-up-down-enter-active, .slide-up-down-leave-active {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
.slide-up-down-enter-from {
  transform: translateY(100%);
  opacity: 0;
}
.slide-up-down-enter-to {
  transform: translateY(0);
  opacity: 1;
}
.slide-up-down-leave-from {
  transform: translateY(0);
  opacity: 1;
}
.slide-up-down-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

/* Animación cuando retrocedemos (Up), la página baja */
.slide-down-up-enter-active, .slide-down-up-leave-active {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
.slide-down-up-enter-from {
  transform: translateY(-100%);
  opacity: 0;
}
.slide-down-up-enter-to {
  transform: translateY(0);
  opacity: 1;
}
.slide-down-up-leave-from {
  transform: translateY(0);
  opacity: 1;
}
.slide-down-up-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
</style>
