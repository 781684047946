import SliderPage from '@/views/slider/SliderPage.vue';

const sliderRoutes = [
  {
    path: '/presentacion/:domain/:type',
    name: 'slider',
    component: SliderPage
  },
  {
    path: '/presentacion',
    redirect: '/' 
  },
  {
    path: '/presentacion/:domain',
    redirect: '/' 
  },
];

export default sliderRoutes;
